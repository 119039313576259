/* Add this at the top of your CSS file */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

/* Color scheme based on provided colors */
:root {
    --primary-color: #855AFF; /* Purple */
    --secondary-color: #00D7AD; /* Teal */
}

.cs-message--incoming > .cs-message__content-wrapper > .cs-message__content {
  background-color: var(--secondary-color);

}

.cs-message--outgoing > .cs-message__content-wrapper > .cs-message__content {
  background-color: var(--primary-color);

}

.cs-avatar>img {
  box-sizing: border-box;
  width: 80%;
  height: 80%;
  border-radius: 50%;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
